import request from '@/utils/request'


// 查询商品订单列表
export function listUser(query) {
  return request({
    url: '/user/goods-orders/list',
    method: 'get',
    params: query
  })
}

// 查询商品订单分页
export function pageUser(query) {
  return request({
    url: '/user/goods-orders/page',
    method: 'get',
    params: query
  })
}

// 查询商品订单详细
export function getUser(data) {
  return request({
    url: '/user/goods-orders/detail',
    method: 'get',
    params: data
  })
}

// 新增商品订单
export function addUser(data) {
  return request({
    url: '/user/goods-orders/add',
    method: 'post',
    data: data
  })
}

// 修改商品订单
export function updateUser(data) {
  return request({
    url: '/user/goods-orders/edit',
    method: 'post',
    data: data
  })
}

// 删除商品订单
export function delUser(data) {
  return request({
    url: '/user/goods-orders/delete',
    method: 'post',
    data: data
  })
}
